import { FlashcardForm } from "../components/FlashcardsForm/FlashcardForm";
import { useCreateFlashcard } from "../util/queries";
import styled from "styled-components";
import { FlashcardList } from "../components/FlashcardList";
import { useLastCreatedFlashcards } from "../util/flashcards";
import { message } from "../util/animation";
import { getSource } from "../util/source";
import {
  GRAMMAR_CONTEXT,
  PRONUNCIATION_CONTEXT,
} from "../components/Controls/consts";
import { FC } from "react";
import { SavedOffline } from "../components/SavedOffline";
import { getPronunciation } from "../util/api";

const LastAddedWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

interface AddPageProps {
  autoListen?: boolean;
}

export const AddPage: FC<AddPageProps> = ({ autoListen }) => {
  const [createFlashcard, , lastCreated] = useCreateFlashcard();

  const lastCreatedList = useLastCreatedFlashcards(lastCreated);

  return (
    <>
      <FlashcardForm
        isAdding
        autoListen={autoListen}
        onSubmit={async (data, formUtil) => {
          // if (data.description) {
          //   data.polText = asUntranslated(data.polText);
          // }

          if (data.context.includes(PRONUNCIATION_CONTEXT)) {
            data.polText = "-";
            data.description = await getPronunciation(data.engText);
          }

          data.source = getSource();
          data.polText = data.context.includes(GRAMMAR_CONTEXT)
            ? "-"
            : data.polText;
          await createFlashcard(data);
          await message(`${data.engText} / ${data.polText}`, 800, "green");
          localStorage.setItem("lastContext", data.context);
          formUtil.reset();
          formUtil.setFieldValue("context", data.context);
        }}
        submitText="Dodaj"
      />
      <LastAddedWrapper>
        <FlashcardList
          flashcards={lastCreatedList}
          startSide="dual"
          children={(ch) => (
            <>
              <SavedOffline />
              {ch}
            </>
          )}
        />
      </LastAddedWrapper>
    </>
  );
};
