export const ALL_CONTEXTS = "ALL";
export const FAVOURITES_CONTEXT = "★";
export const COLLECTION_CONTEXT = "📋";

export const GRAMMAR_CONTEXT = "GRM";

export const PRONUNCIATION_CONTEXT = "PRON";

export const COLLECTION_ICON = "⚑";

export const favouriteColor = "#f4ec99";
