import { Modal } from "../Modal";
import { Buttons, ModalButton } from "../ModalComponents";
import { FlashcardDto, QuizQuestionTopic, quizTopics } from "../../types";
import { FC, useContext, useState } from "react";
import { QuestionModal } from "../Quizes/QuestionModal";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import {
  getTopicAbbrev,
  getTopicCollection,
  useTopicColors,
} from "../Quizes/utils/quiz";
import { getFlashcardCollections } from "../../util/collections";
import { READ_ICON, ReadOutIcon } from "../ReadOut";
import { usePlayFlashcard } from "./hooks";
import { scaleOnHover } from "../Style";
import { TranslationLink } from "../TranslationLink";
import { copyAsPronounceFlashcard } from "../../util/api";

const Title = styled.p`
  text-align: center;
  font-size: 1.4em;
  margin: 5px;
`;

const ReadOutButton = styled.button`
  all: unset;
  ${scaleOnHover()};
  font-size: 1.4em;
  align-self: center;
  margin: 10px;
`;

const CopyAsPronounceButton = styled.button`
  all: unset;
  ${scaleOnHover()};
  font-size: 1.4em;
  align-self: center;
  margin: 10px;
`;

const GeneralButtons = styled(Buttons)`
  display: flex;
  justify-content: center;
  font-size: 2em;
  gap: 80px;
`;

const QuizifyButtons = styled(Buttons)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  p {
    margin: 5px;
  }

  button {
    border-radius: 10px;
  }
`;

export type FlashcardSimpleDto = Pick<
  FlashcardDto,
  "_id" | "description" | "engText" | "polText" | "context"
>;

interface MoreMenuProps {
  flashcard: FlashcardSimpleDto;
  onClose: () => void;
}

export const MoreMenu: FC<MoreMenuProps> = (props) => {
  const { flashcard, onClose } = props;
  const { toggleCollectionItem, collections } = useContext(AppContext);
  const getTopicColor = useTopicColors();

  const flashcardCollections = getFlashcardCollections(
    flashcard._id,
    collections
  );

  const [quizifying, setQuizyfying] = useState<QuizQuestionTopic | null>(null);

  const isQuizyfied = (topic: QuizQuestionTopic) => {
    return flashcardCollections
      .map((col) => col.name)
      .includes(getTopicCollection(topic));
  };

  const { playOne } = usePlayFlashcard(flashcard._id);

  return (
    <Modal onClickOutside={onClose} width={400}>
      <Title>{flashcard.engText} 🪄</Title>

      <GeneralButtons>
        <ReadOutButton
          onClick={(e) => {
            playOne(flashcard);
            onClose();
          }}
        >
          <ReadOutIcon>{READ_ICON}</ReadOutIcon>
        </ReadOutButton>
        <TranslationLink
          text={flashcard.description}
          beforeNavigate={onClose}
          service="google"
        />
        <CopyAsPronounceButton
          onClick={async () => {
            await copyAsPronounceFlashcard(flashcard);
            onClose();
          }}
        >
          📚
        </CopyAsPronounceButton>
      </GeneralButtons>

      <QuizifyButtons>
        {quizTopics.map((topic) => (
          <ModalButton
            onClick={() => setQuizyfying(topic)}
            magicColor={getTopicColor(topic)}
            magicReverse
          >
            <p>{isQuizyfied(topic) ? "✅" : "🎲"}</p>
            <p>{getTopicAbbrev(topic)}</p>
          </ModalButton>
        ))}
      </QuizifyButtons>

      <Buttons>
        <ModalButton magicColor="pink" onClick={onClose}>
          Cancel
        </ModalButton>
      </Buttons>

      {quizifying && (
        <QuestionModal
          type={quizifying}
          question="new"
          newText={flashcard.description}
          onClose={() => {
            setQuizyfying(null);
            onClose();
          }}
          onAdd={() => {
            toggleCollectionItem(
              getTopicCollection(quizifying),
              flashcard._id,
              true
            );
          }}
        />
      )}
    </Modal>
  );
};
