import { FC, useContext, useEffect, useState } from "react";
import { FlashcardList } from "../components/FlashcardList";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FlashcardDto } from "../types";
import { PRONUNCIATION_CONTEXT } from "../components/Controls/consts";
import { AppContext } from "../components/AppContext";

const BackBtn = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

const OptionsContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const shuffleArray = (array: any[]) => {
  return array.sort(() => Math.random() - 0.5);
};

const sortAlphabetically = (array: FlashcardDto[]) => {
  return [...array].sort((a, b) =>
    a.engText.localeCompare(b.engText, undefined, { sensitivity: "base" })
  );
};

export const PronounceExercisePage: FC = () => {
  const [flashcards, setFlashcards] = useState<FlashcardDto[]>([]);
  const appContext = useContext(AppContext);

  const [displayFlashcards, setDisplayFlashcards] = useState<FlashcardDto[]>(
    []
  );
  const [sortOption, setSortOption] = useState<string>("default");

  const fromCache = appContext.flashcards.every((fl) => fl.cache);

  useEffect(() => {
    const fetchFlashcards = async () => {
      try {
        const fetchedFlashcards = appContext.flashcards.filter(
          (fl) => fl.context === PRONUNCIATION_CONTEXT
        );
        setFlashcards(fetchedFlashcards);
        setDisplayFlashcards(fetchedFlashcards); // Set default order initially
      } catch (error) {
        console.error("Error fetching flashcards:", error);
      }
    };

    fetchFlashcards();
  }, [appContext.flashcards.length, fromCache]);

  useEffect(() => {
    let sortedFlashcards = flashcards;

    if (sortOption === "shuffle") {
      sortedFlashcards = shuffleArray([...flashcards]);
    } else if (sortOption === "proficiency") {
      sortedFlashcards = [...flashcards].sort(
        (a, b) => a.proficiency - b.proficiency
      );
    } else if (sortOption === "alphabetical") {
      sortedFlashcards = sortAlphabetically([...flashcards]);
    }

    setDisplayFlashcards(sortedFlashcards);
  }, [sortOption, flashcards]);

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(e.target.value);
  };

  return (
    <>
      <OptionsContainer>
        <label htmlFor="sortOptions">Sort By: </label>
        <select id="sortOptions" value={sortOption} onChange={handleSortChange}>
          <option value="default">Default</option>
          <option value="proficiency">Proficiency</option>
          <option value="shuffle">Shuffle</option>
          <option value="alphabetical">Alphabetical</option>
        </select>
      </OptionsContainer>
      <FlashcardList flashcards={displayFlashcards} startSide="dual" />
      <BackBtn>
        <Link to={"/"}>Back</Link>
      </BackBtn>
    </>
  );
};
