import { FC } from "react";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../util/queries";
import { createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router";
import { MainPage } from "../pages/MainPage";
import { AddPage } from "../pages/AddPage";
import "normalize.css";
import { EditPageModal } from "../pages/EditPageModal";
import { AppContextProvider } from "./AppContext";
import { StoriesPage } from "../pages/StoriesPage";
import { SpeakPage } from "../pages/SpeakPage";
import { TopicConfigPage } from "../pages/TopicConfigPage";
import { CollectionConfigPage } from "../pages/CollectionConfigPage";
import { ConfigPage } from "../pages/ConfigPage";
import { InsightsConfigPage } from "../pages/InsightsConfigPage";
import { BabbleConfigPage } from "../pages/BabbleConfigPage";
import { QuizPage } from "../pages/QuizPage";
import { PronounceExercisePage } from "../pages/PronounceExercisePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    children: [
      {
        path: "/edit/:id",
        element: <EditPageModal />,
      },
      {
        path: "/audio",
        element: null,
      },
      {
        path: "/writer",
        element: null,
      },
    ],
  },
  {
    path: "/add",
    element: <AddPage />,
  },
  {
    path: "/add-voice",
    element: <AddPage autoListen />,
  },
  {
    path: "/stories",
    element: <StoriesPage />,
  },
  {
    path: "/speak/*",
    element: <SpeakPage />,
  },
  {
    path: "/config",
    element: <ConfigPage />,
  },
  {
    path: "/config/topic",
    element: <TopicConfigPage />,
  },
  {
    path: "/config/babble",
    element: <BabbleConfigPage />,
  },
  {
    path: "/config/collection",
    element: <CollectionConfigPage />,
  },
  {
    path: "/config/insights",
    element: <InsightsConfigPage />,
  },
  {
    path: "/quiz/*",
    element: <QuizPage />,
  },
  {
    path: "/pronounce",
    element: <PronounceExercisePage />,
  },
]);

export const App: FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <RouterProvider router={router} />
        </AppContextProvider>
      </QueryClientProvider>
    </>
  );
};
